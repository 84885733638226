// extracted by mini-css-extract-plugin
export var column = "UserNetworkPrivateLineProject_Details_LocationDocuments__column__dC9j0";
export var container = "UserNetworkPrivateLineProject_Details_LocationDocuments__container__nPm8K";
export var descriptionContent = "UserNetworkPrivateLineProject_Details_LocationDocuments__descriptionContent__PAmU5";
export var documentsHeading = "UserNetworkPrivateLineProject_Details_LocationDocuments__documentsHeading__Fa0cu";
export var downloadButton = "UserNetworkPrivateLineProject_Details_LocationDocuments__downloadButton__QX8JV";
export var fileName = "UserNetworkPrivateLineProject_Details_LocationDocuments__fileName__jtsfK";
export var fileNameAndIconWrapper = "UserNetworkPrivateLineProject_Details_LocationDocuments__fileNameAndIconWrapper__hf7Ip";
export var flex = "UserNetworkPrivateLineProject_Details_LocationDocuments__flex__mld1S";
export var flexColumn = "UserNetworkPrivateLineProject_Details_LocationDocuments__flexColumn__O8Izh";
export var gap1 = "UserNetworkPrivateLineProject_Details_LocationDocuments__gap1__fb2G8";
export var gap2 = "UserNetworkPrivateLineProject_Details_LocationDocuments__gap2__NrUY3";
export var gap3 = "UserNetworkPrivateLineProject_Details_LocationDocuments__gap3__bm0wd";
export var gap4 = "UserNetworkPrivateLineProject_Details_LocationDocuments__gap4__eLgND";
export var gap5 = "UserNetworkPrivateLineProject_Details_LocationDocuments__gap5___Zs4T";
export var geographicalRegion = "UserNetworkPrivateLineProject_Details_LocationDocuments__geographicalRegion__qM3iw";
export var geographicalRegionAndIconWrapper = "UserNetworkPrivateLineProject_Details_LocationDocuments__geographicalRegionAndIconWrapper__teW9F";
export var geographicalRegionRow = "UserNetworkPrivateLineProject_Details_LocationDocuments__geographicalRegionRow__a9UtT";
export var geographicalRegionTitle = "UserNetworkPrivateLineProject_Details_LocationDocuments__geographicalRegionTitle__XRKP7";
export var icon = "UserNetworkPrivateLineProject_Details_LocationDocuments__icon__qlqHg";
export var panelBody = "UserNetworkPrivateLineProject_Details_LocationDocuments__panelBody__l9p0G";
export var panelContainer = "UserNetworkPrivateLineProject_Details_LocationDocuments__panelContainer__yEVXg";
export var row = "UserNetworkPrivateLineProject_Details_LocationDocuments__row__E9aRd";