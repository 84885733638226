// extracted by mini-css-extract-plugin
export var colLeft = "UserNetworkPrivateLineProject_Details__colLeft__LNOS_";
export var colRight = "UserNetworkPrivateLineProject_Details__colRight__Z5lpT";
export var column = "UserNetworkPrivateLineProject_Details__column__cb5y7";
export var columnWrapper = "UserNetworkPrivateLineProject_Details__columnWrapper__O6cGA";
export var descriptionContent = "UserNetworkPrivateLineProject_Details__descriptionContent__pyRQN";
export var descriptionHeading = "UserNetworkPrivateLineProject_Details__descriptionHeading__msDZB";
export var flex = "UserNetworkPrivateLineProject_Details__flex__xPkJM";
export var flexColumn = "UserNetworkPrivateLineProject_Details__flexColumn__h8FUy";
export var gap1 = "UserNetworkPrivateLineProject_Details__gap1__hn8Js";
export var gap2 = "UserNetworkPrivateLineProject_Details__gap2__qH629";
export var gap3 = "UserNetworkPrivateLineProject_Details__gap3__ck2pr";
export var gap4 = "UserNetworkPrivateLineProject_Details__gap4__cfvrj";
export var gap5 = "UserNetworkPrivateLineProject_Details__gap5__Qb2N3";
export var lastUpdated = "UserNetworkPrivateLineProject_Details__lastUpdated__u2frP";
export var reviewStatusWarning = "UserNetworkPrivateLineProject_Details__reviewStatusWarning__gHfGZ";
export var row = "UserNetworkPrivateLineProject_Details__row__BQVye";